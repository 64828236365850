.title{
    font-family: "JetBrains Mono", monospace;
    font-weight: 800;
    text-transform: uppercase;

    &.one{
        font-size: 1.625rem;

        @media (min-width: 1024px){
            font-size: 3rem;
        }
    }

    &.two{
        font-size: 1.25rem;

        @media (min-width: 1024px){
            font-size: 1.5rem;
        }
    }

    &.three{
        font-family: 'Montserrat', sans-serif;
        font-size: 1.1rem;
        text-transform: none;

        @media (min-width: 1024px){
            font-size: 1.375rem;
        }
    }

    &.four{
        font-family: 'Montserrat', sans-serif;
        font-size: 1rem;
        text-transform: none;

        @media (min-width: 1024px){
            font-size: 1.1rem;
        }
    }

    &.center{
        text-align: center;
    }

    &.dark{
        color: $brand-dark-color;
    }

    &.blue{
        color: $brand-color;
    }

    &.white{
        color: $soft-white;    
    }
}

.paragraph{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: $text-color;

    strong, .bold{
        font-weight: 800;
    }

    &.center{
        text-align: center;
    }

    &.blue{
        color: $brand-color;
    }

    &.white{
        color: $text-color-secondary;    
    }
}