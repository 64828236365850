@import "./variables";
@import "./typography.scss";

html,
body,
div,
p,
header,
footer,
main,
section,
nav,
ul,
li,
a,
button,
input,
img,
article,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  border: none;
}

html {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

body,
button,
a,
input {
  font-family: "Montserrat", sans-serif;
  color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "JetBrains Mono", monospace;
}

p {
  line-height: 1.4;
}

button {
  cursor: pointer;
}

.container {
  width: 85%;
  max-width: $max-width;
  padding: 2.5rem 0;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 4.5rem 0;
  }
}
.hidden {
  display: none;
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.active {
  color: $brand-color;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  position: absolute;
  display: block;
  width: fit-content;
  top: 50%;
  z-index: 2;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: rgba(0, 30, 50, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);

  transition: border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;

  &:hover,
  &:focus {
    border-color: white;
    color: white;
  }
}

.alice-carousel__next-btn-item {
  right: 0;
}

ul.alice-carousel__dots li.alice-carousel__dots-item {
  background-color: transparent;
  border: thin solid $brand-color;
  transition: all 0.2s linear;
}

ul.alice-carousel__dots .alice-carousel__dots-item.__active {
  width: 31px;
  background-color: $brand-color;
  border-radius: 8px;
}

@media (max-width: 1023px) {
  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    display: none;
  }
}

@media (min-width: 1024px) {
  .alice-carousel__dots {
    display: none;
  }
}


