.yt-lite {
  height: 100%;
}
.yt-lite .lty-playbtn {
  background-color: transparent;
  background-image: none;
}
.yt-lite:hover .lty-playbtn,
.yt-lite .lty-playbtn:hover {
  background-color: transparent;
}
.yt-lite .lty-playbtn::before {
  border-color: none;
  border-style: none;
  border-width: none;
}
.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  top: 75%;
  left: 20px;
  transform: none;
  @media (min-width: 415px) {
    top: 80%;
  }
  @media (min-width: 550px) {
    top: 84%;
  }
  @media (min-width: 768px) {
    top: 88%;
  }
  @media (min-width: 900px) {
    top: 90%;
  }
  @media (min-width: 1024px) {
    top: 75%;
  }
}
