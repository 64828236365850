@import "../../styles/variables";

.container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: $border-radius;
  background-color: $brand-dark-color;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.8);
  color: $text-color-secondary;
  font-size: 0.875rem;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  z-index: 10;
  position: fixed;
  bottom: 4px;
  left: 4px;
  right: 4px;

  transform: translateY(200px);
  animation: slideUp 0.5s forwards;

  a {
    color: $text-color-secondary;
    text-decoration: underline;
  }

  button {
    height: 50px;
    width: 186px;
    font-size: 0.875rem;
    font-weight: bold;
    border: none;
    margin-top: 8px;
    border-radius: $btn-border-radius;
    transition: all 0.3s ease-in-out;
    background-color: $brand-color;
    color: $text-color-secondary;
    padding: 0 16px;
  }

  button:hover,
  button:focus {
    background-color: $highlight-color;
  }

  @keyframes slideUp {
    to {
      transform: translateY(0);
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 24px;
    max-width: 1250px;
    bottom: 16px;

    button {
      margin-top: 0;
    }
  }
}
