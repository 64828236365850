$text-color: #2d2d2d;
$brand-color: #0000ff;
$brand-dark-color: #001e32;
$highlight-color: #3532ff;
$text-color-secondary: #fff;
$color-yellow-icons: #f7c82a;
$soft-white: #f5f5f5;
$max-width: 1396px;
$border-radius: 20px;
$btn-border-radius: 2px;
