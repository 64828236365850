@import "../../styles/variables";

.wrapper {
  background-color: $brand-dark-color;
}

.container {
  padding: 2rem 1.5rem 1rem;
  position: relative;
  max-width: $max-width;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    padding: 4rem 2.5rem 3rem;
    margin: auto;
  }
}

.logo {
  position: absolute;
  top: 2rem;
  right: 1.5rem;
  object-fit: contain;

  @media (min-width: 1024px) {
    position: initial;
    width: 192px;
    height: 208px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &,
  li,
  a {
    color: $text-color-secondary;
  }

  li {
    margin-bottom: 1.5rem;
  }

  a {
    transition: color 0.3s ease-in-out;

    &:hover,
    &:focus {
      color: $highlight-color;
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;

    div {
      flex: 1;
    }
  }
}

.title {
  font-size: 1.25rem;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.socialList {
  column-count: 2;
  width: fit-content;
  font-size: 1rem;

  a {
    font-weight: 300;
  }

  @media (min-width: 1024px) {
    column-count: 1;
  }
}

.contactList {
  li {
    margin: 1rem 0;
    font-weight: 300;

    a {
      font-weight: 400;
    }

    .career {
      font-family: "Jet Brains", monospace;
      font-size: 1rem;
      font-weight: 800;
      text-transform: uppercase;
    }
  }
}

.footnote {
  font-size: 0.875rem;
  text-align: center;
  color: #2b80d5;
  border-top: 1px solid #2b80d5;
  padding: 2rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
